import axios from 'axios'
// import Vue from 'vue'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

axios.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (/401/.test(error.message)) {
      window.parent.postMessage(
        { type: 'JUMP_TO_LOGIN_FROM_UNIVERSAL_FRONT' },
        '*'
      )
    }
    return Promise.reject(error)
  }
)

export const $get = (url, params, header) => {
  let baseUrl = url
  let result = []
  for (let k in params) {
    result.push(`${k}=${params[k]}`)
  }
  if (result.length > 0) {
    const query = result.join('&')
    baseUrl = `${url}?${query}`
  }
  let token = sessionStorage.getItem('tk')

  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl, {
        headers: {
          ...header,
          Authorization: token ? 'Bearer ' + token : undefined
        }
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}

export const $post = (url, data = {}, config = {}) => {
  let token = sessionStorage.getItem('tk')
  config = {
    ...config,
    headers: { Authorization: token ? 'Bearer ' + token : undefined }
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, config)
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        console.log(err)
        // Vue.prototype.$Loading.finish()
        reject(err)
      })
  })
}
