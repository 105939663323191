var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"agm-scroll-container",staticClass:"agm-scroll-container no-select",style:(_vm.containerStyle)},[_c('div',{ref:"agm-scroll-wrapper",staticClass:"agm-scroll-wrapper no-select",style:({ right: _vm.showScrollBar ? _vm.scrollBarWidth + 4 + 'px' : 0 })},[_vm._t("default")],2),_c('div',{ref:"agm-scroll-bar",staticClass:"agm-scroll-bar no-select",style:({
      display: _vm.showScrollBar ? 'block' : 'none',
      width: _vm.scrollBarWidth + 'px',
      backgroundColor: _vm.barBgColor
    })}),_c('div',{ref:"agm-scroll-rail",staticClass:"agm-scroll-rail no-select",style:({
      display: _vm.showScrollBar ? 'block' : 'none',
      width: _vm.scrollBarWidth + 'px'
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }