<template>
  <div class="loading-bar" v-show="show">
    <div id="loading" class="loading">
      <div class="face">
        <div class="load">{{ percent }}<span>%</span></div>
      </div>
    </div>
    <p v-show="text" class="loading-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    failedColor: {
      type: String,
      default: 'error'
    }
  },
  data() {
    return {
      percent: 0,
      status: 'success',
      show: false,
      timer: null,
      text: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      let t = 328,
        n = 0
      const loading = document.getElementById('loading')
      if (!loading) return
      this.timer = setInterval(() => {
        loading.style.backgroundPositionY = -t * n + 'px'
        n++
        if (n >= 100) {
          n = 0
        }
      }, 40)
    })
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: Mouse;
  src: url('./Mouse.otf');
}
.loading-bar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('./bg.png') no-repeat;
  background-size: cover;
  overflow: hidden;
}
.loading-text {
  height: 32px;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  line-height: 32px;
}
.loading {
  width: 328px;
  height: 328px;
  overflow: hidden;
  background-image: url('./move1.png');
  background-position-x: center;
  background-position-y: 0px;
  background-size: 100%;
  margin: 0 auto;
  position: relative;
  .face {
    width: 185px;
    height: 185px;
    overflow: hidden;
    background-image: url('./face.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .load {
    font-family: Mouse;
    font-size: 60px;
    color: #00a0ff;
    position: absolute;
    left: 53%;
    top: 53%;
    transform: translate(-50%, -50%);
    span {
      font-size: 24px;
    }
  }
}
</style>
