import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/reset.css'
import '@/assets/css/global.css'
import '@/assets/css/font.css'

import AgmScroll from './plugins/scroll/index'
import AgmMessage from './plugins/message/index'
import LoadingBar from './plugins/loading/index'
import * as vClickOutside from './plugins/util/click-outside'
import { $get, $post } from '@/assets/js/http'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(AgmScroll)
Vue.use(AgmMessage)
Vue.use(vClickOutside)
Vue.prototype.$Loading = LoadingBar
Vue.prototype.$axios = { $get, $post }

// 在loading过程中切环路 取消loading展示
router.beforeEach((to, from, next) => {
  Vue.prototype.$Loading.destroy()
  next()
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
