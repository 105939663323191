import LoadingBar from './index.vue'
import Vue from 'vue'

LoadingBar.newInstance = properties => {
  const _props = properties || {}

  const Instance = new Vue({
    data: _props,
    render(h) {
      return h(LoadingBar, {
        props: _props
      })
    }
  })

  const component = Instance.$mount()
  document.body.appendChild(component.$el)
  const loading_bar = Instance.$children[0]

  return {
    update(options) {
      if ('percent' in options) {
        loading_bar.percent = options.percent
      }
      if (options.status) {
        loading_bar.status = options.status
      }
      if ('show' in options) {
        loading_bar.show = options.show
      }
      if ('text' in options) {
        loading_bar.text = options.text
      }
    },
    component: loading_bar,
    destroy() {
      document.body.removeChild(
        document.getElementsByClassName('loading-bar')[0]
      )
    }
  }
}

export default LoadingBar
