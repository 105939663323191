<template lang="html">
  <div
    ref="agm-scroll-container"
    :style="containerStyle"
    class="agm-scroll-container no-select"
  >
    <div
      ref="agm-scroll-wrapper"
      :style="{ right: showScrollBar ? scrollBarWidth + 4 + 'px' : 0 }"
      class="agm-scroll-wrapper no-select"
    >
      <slot />
    </div>

    <div
      ref="agm-scroll-bar"
      :style="{
        display: showScrollBar ? 'block' : 'none',
        width: scrollBarWidth + 'px',
        backgroundColor: barBgColor
      }"
      class="agm-scroll-bar no-select"
    />
    <div
      ref="agm-scroll-rail"
      :style="{
        display: showScrollBar ? 'block' : 'none',
        width: scrollBarWidth + 'px'
      }"
      class="agm-scroll-rail no-select"
    />
  </div>
</template>

<script>
import { getStyle } from '../../util/assist'
let pageY = 0
let startBarDomTop = 0
export default {
  name: 'AgmScroll',
  props: {
    height: {
      type: Number,
      default: 0
    },
    scrollBarWidth: {
      type: Number,
      default: 6
    },
    barBgColor: {
      type: String,
      default: '#ededed'
    }
  },
  data() {
    return {
      containerDom: {},
      wrapperDom: {},
      barDom: {},
      railDom: {},

      containerHeight: 0,
      wrapperHeight: 0,
      barHeight: 0,
      barMaxTop: 0,
      wrapperMaxTop: 0,
      scrollDistance: 100,

      showScrollBar: false
    }
  },
  computed: {
    containerStyle() {
      return this.showScrollBar
        ? { height: this.height + 'px' }
        : { height: this.wrapperHeight + 'px' }
    }
  },
  mounted() {
    this.containerDom = this.$refs['agm-scroll-container']
    this.wrapperDom = this.$refs['agm-scroll-wrapper']
    this.barDom = this.$refs['agm-scroll-bar']
    this.railDom = this.$refs['agm-scroll-rail']

    // initBar
    this.calcShowBar()

    this.addEvent(this.containerDom, 'mousewheel', event => {
      if (!this.showScrollBar) return
      const curTop = parseFloat(getStyle(this.wrapperDom, 'top'))
      let newTop = 0
      if (event.delta < 0) {
        if (curTop - this.scrollDistance > -this.wrapperMaxTop) {
          newTop = curTop - this.scrollDistance
        } else {
          newTop = -this.wrapperMaxTop
        }
      } else if (curTop + this.scrollDistance <= 0) {
        newTop = curTop + this.scrollDistance
      } else {
        newTop = 0
      }

      this.wrapperDom.style.top = newTop + 'px'
      this.refreshBar()
      this.$emit('scrollTop', Math.abs(newTop || 0))
    },false)

    // this.updateScroll()

    document.addEventListener('mousedown', event => {
      if (event.target === this.barDom) {
        pageY = event.pageY
        startBarDomTop = parseFloat(getStyle(this.barDom, 'top'))

        document.addEventListener('mousemove', this.mouseMoveHandle)
        document.addEventListener('mouseup', this.mouseUpHandle)
      }
    })
  },
  updated() {
    this.updateScroll()
  },
  methods: {
    calcShowBar() {
      this.wrapperHeight = this.wrapperDom.offsetHeight
      this.showScrollBar = this.wrapperHeight > this.height
    },
    updateScroll() {
      this.containerHeight = this.containerDom.clientHeight
      this.wrapperHeight = this.wrapperDom.offsetHeight

      // initBar
      this.calcShowBar()

      if (this.containerHeight < this.scrollDistance + 20) {
        this.scrollDistance = (this.containerHeight / 3) | 0
      } else {
        this.scrollDistance = 100
      }

      this.barHeight =
        (this.containerHeight * this.containerHeight) / this.wrapperHeight

      this.barDom.style.height = this.barHeight + 'px'
      this.barMaxTop = this.containerHeight - this.barHeight
      this.wrapperMaxTop = this.wrapperHeight - this.containerHeight
      const wrapperTop = Math.abs(parseFloat(getStyle(this.wrapperDom, 'top')))
      if (wrapperTop > this.wrapperMaxTop) {
        this.wrapperDom.style.top = -this.wrapperMaxTop + 'px'
        this.refreshBar()
      }
    },
    refreshBar() {
      const wrapperTop = Math.abs(parseFloat(getStyle(this.wrapperDom, 'top')))
      this.barDom.style.top =
        (wrapperTop * this.containerHeight) / this.wrapperHeight + 'px'
    },
    getScrollTop() {
      return Math.abs(parseFloat(getStyle(this.wrapperDom, 'top')))
    },
    goBottom() {
      this.wrapperDom.style.top = -this.wrapperMaxTop + 'px'
      this.refreshBar()
    },
    goTop() {
      this.wrapperDom.style.top = 0 + 'px'
      this.refreshBar()
    },
    goAnyWhere(top) {
      if (top < -this.wrapperMaxTop) {
        this.wrapperDom.style.top = -this.wrapperMaxTop + 'px'
      } else if (top > 0) {
        this.wrapperDom.style.top = -top + 'px'
      } else {
        this.wrapperDom.style.top = top + 'px'
      }
      this.refreshBar()
    },
    mouseMoveHandle(event) {
      let top = startBarDomTop + event.pageY - pageY
      if (top >= this.barMaxTop) {
        top = this.barMaxTop
      } else if (top <= 0) {
        top = 0
      }
      this.barDom.style.top = top + 'px'
      this.refreshWrapper(top)
    },
    mouseUpHandle() {
      document.removeEventListener('mousemove', this.mouseMoveHandle)
      document.removeEventListener('mouseup', this.mouseUpHandle)
    },
    refreshWrapper(bartop) {
      this.wrapperDom.style.top =
        '-' + (this.wrapperHeight * bartop) / this.containerHeight + 'px'
    },
    addEvent(el, type, fn, capture) {
      const _eventCompat = function(event) {
        const type = event.type
        if (type === 'DOMMouseScroll' || type === 'mousewheel') {
          event.delta = event.wheelDelta
            ? event.wheelDelta / 120
            : -(event.detail || 0) / 3
        }
        if (event.srcElement && !event.target) {
          event.target = event.srcElement
        }
        if (!event.preventDefault && event.returnValue !== undefined) {
          event.preventDefault = function() {
            event.returnValue = false
          }
        }
        return event
      }
      if (type === 'mousewheel' && document.mozFullScreen !== undefined) {
        type = 'DOMMouseScroll'
      }
      el.addEventListener(
        type,
        function(event) {
          event.preventDefault()
          fn.call(this, _eventCompat(event))
        },
        capture || false
      )
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.agm-scroll-container {
  position: relative;
  overflow: hidden;
  .agm-scroll-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 10px;
  }

  .agm-scroll-bar {
    width: 6px;
    position: absolute;
    top: 0;
    border-radius: 3px;
    z-index: 99;
    right: 1px;
    cursor: pointer;
  }

  .agm-scroll-rail {
    width: 7px;
    height: 100%;
    position: absolute;
    top: 0px;
    border-radius: 7px;
    z-index: 90;
    right: 1px;
  }
}
</style>
