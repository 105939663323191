import Vue from 'vue'
import messageVue from './index.vue'

const defaults = {
  show: false,
  text: '',
  duration: '2000',
  spacing: 20, // 间距
  position: 'center'
}
const MessageVueConstructor = Vue.extend(messageVue)

MessageVueConstructor.prototype.close = function() {
  // eslint-disable-next-line
  this.$on('after-leave', _ => {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    this.$destroy()
  })
  this.show = false
}
const messageBox = (options = {}, fn) => {
  if (Vue.prototype.$isServer) return
  options = Object.assign({}, defaults, options)
  const parent = document.body || document.documentElement
  const msgDom = Array.from(document.getElementsByClassName('agm-message'))
  msgDom.forEach(item => {
    item && item.remove()
    // options.spacing += defaults.spacing + item.offsetHeight
  })
  const instance = new MessageVueConstructor({
    el: document.createElement('div'),
    data: options
  })
  parent.appendChild(instance.$el)
  Vue.nextTick(() => {
    instance.show = true
    setTimeout(function() {
      instance.close()
      if (Object.prototype.toString.call(fn) === '[object Function]') {
        fn()
      }
    }, options.duration)
  })
  return instance
}

export default messageBox
