import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:'/style'
  },
  {
    path: '/style',
    name: 'style',
    component: () =>
      import(/* webpackChunkName: "style" */ '../views/Style.vue')
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: () =>
      import(/* webpackChunkName: "copyright" */ '../views/Copyright.vue')
  },
  {
    path: '/copyrightResult',
    name: 'copyrightResult',
    component: () =>
      import(
        /* webpackChunkName: "copyrightResult" */ '../views/CopyrightResult.vue'
      )
  },
  {
    path: '/watermark',
    name: 'watermark',
    component: () =>
      import(/* webpackChunkName: "watermark" */ '../views/Watermark.vue')
  },
  {
    path: '/watermarkAdd',
    name: 'watermarkAdd',
    component: () =>
      import(/* webpackChunkName: "watermarkAdd" */ '../views/WatermarkAdd.vue')
  },
  {
    path: '/watermarkResult',
    name: 'watermarkResult',
    component: () =>
      import(
        /* webpackChunkName: "watermarkResult" */ '../views/WatermarkResult.vue'
      )
  },
  {
    path: '/watermarkDetection',
    name: 'watermarkDetection',
    component: () =>
      import(
        /* webpackChunkName: "watermarkDetection" */ '../views/WatermarkDetection.vue'
      )
  },
  {
    path: '/WatermarkDetectionResult',
    name: 'watermarkDetectionResult',
    component: () =>
      import(
        /* webpackChunkName: "WatermarkDetectionResult" */ '../views/WatermarkDetectionResult.vue'
      )
  },
  {
    path: '/segmentation',
    name: 'segmentation',
    component: () =>
      import(/* webpackChunkName: "segmentation" */ '../views/Segmentation.vue')
  },
  {
    path: '/segmentationResult',
    name: 'segmentationResult',
    component: () =>
      import(
        /* webpackChunkName: "segmentationResult" */ '../views/SegmentationResult.vue'
      )
  },
  {
    path: '/smartTags',
    name: 'smartTags',
    component: () =>
      import(/* webpackChunkName: "copyright" */ '../views/SmartTags.vue')
  },
  {
    path: '/smartTagsResult',
    name: 'smartTagsResult',
    component: () =>
      import(/* webpackChunkName: "copyright" */ '../views/SmartTagsResult.vue')
  },
  {
    path: '/create',
    name: 'create',
    component: () =>
      import(/* webpackChunkName: "create" */ '../views/Create.vue')
  },
  {
    path: '/compose',
    name: 'compose',
    component: () =>
      import(/* webpackChunkName: "compose" */ '../views/Compose.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
