<template>
  <transition name="agm-message">
    <div
      v-if="show"
      :style="{ top: `${winHeight + spacing}px !important` }"
      class="agm-message"
    >
      <span class="agm-message-text" v-html="text" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AgmMessage',
  data() {
    return {
      text: '',
      show: false,
      spacing: 0
    }
  },
  computed: {
    winHeight() {
      return window.screen.availHeight / 2 - 100
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.agm-message {
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 25px 30px;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  z-index: 1000000;
  font-size: 0;
  .agm-message-text {
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
  }
}
</style>
