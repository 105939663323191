<template>
  <div id="app">
    <router-view />
    <div v-if="!/create|style/.test($route.path)" class="page-logo">
      <img src="@/assets/images/logo/logo1.png" alt="" />
      <div class="logo-title">富媒体产业综合服务平台 V1.0</div>
    </div>

    <div
      v-if="!/create|style/.test($route.path)"
      class="page-language__switch"
      @click="handleSwitch"
    >
      {{ $i18n.locale | getLang }}
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    getLang(val) {
      return {
        en: 'CN',
        ch: 'EN'
      }[val]
    }
  },
  methods: {
    handleSwitch() {
      const currentLocale = this.$i18n.locale
      const locales = this.$i18n.availableLocales
      this.$i18n.locale = locales.filter(locale => locale !== currentLocale)[0]
    }
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
}

.page-logo {
  position: absolute;
  top: 0.2rem;
  left: 0.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: auto;
    height: 0.24rem;
    margin-right: 0.2rem;
  }

  .logo-title {
    color: #fff;
    font-size: 0.18rem;
  }
}

.page-language__switch {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  right: 40px;
  background: url('~@/assets/images/lan.png') no-repeat center / 100% 100%;
  font-size: 16px;
  font-weight: 600;
  color: rgba(83, 145, 249, 1);
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
</style>
